import React from 'react';

const DefaultLoading: React.FC = () => {
    
    
    return (
      <div>Loading...</div>
    );
  };
  
  export default DefaultLoading;
  